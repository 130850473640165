<template>
  <div class="home">
    <eden-page-header :title="'Gardeners'">
      <template slot="actions">
        <div class="page--head-actions is-flex is-flex-wrap align-center">
          <el-button type="primary" @click="addGardener">
            Add Gardener
          </el-button>
        </div>
      </template>
    </eden-page-header>
    <eden-page-stats
      :title="'Overview'"
      :loading="summary.loading"
      :stats="stats"
      :show-periods="true"
      :periods="periods"
      @set-period="getGardenersSummary"
    />
    <payment-tracker-overview
      v-if="allowAccessFor(['admin', 'gardener_pro'])"
      :view="'admin'"
    />
    <div>
      <eden-table-actions :title="title" @search="search">
        <template slot="actions">
          <el-button
            type="plain"
            icon="eden-icon-upload"
            class="ml-10"
            :disabled="loading || !pageData.length"
            @click="exporting = true"
          >
            Export
          </el-button>
        </template>
      </eden-table-actions>
      <template v-if="loading">Loading...</template>
      <template v-else>
        <el-table :data="pageData">
          <el-table-column min-width="240">
            <template slot="header">
              <div class="table--header">
                <span>Name</span>
              </div>
            </template>
            <template v-slot="scope">
              <router-link
                :to="{
                  name: 'gardeners.gardener',
                  params: { id: scope.row.id },
                }"
              >
                <span class="font-sm text-primary">
                  {{ formatName(scope.row.name) }}</span
                ></router-link
              >
              <span class="text-grey-tertiary">{{ scope.row.email }}</span>
            </template>
          </el-table-column>
          <el-table-column width="120">
            <template slot="header">
              <div class="table--header">
                <span>Slack Id</span>
              </div>
            </template>
            <template v-slot="scope">
              <span class="text-grey-tertiary">{{
                scope.row.slack_id || "-"
              }}</span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Status</span>
              </div>
            </template>
            <template v-slot="scope">
              <span class="font-sm table--status">
                <el-tag :type="scope.row.available ? 'success' : 'warning'">{{
                  scope.row.available ? "Available" : "Not Available"
                }}</el-tag>
              </span>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Customers </span>
              </div>
            </template>
            <template v-slot="scope">
              <span
                >{{ scope.row.customers }} /
                {{ scope.row.customers_threshold }}</span
              >
            </template>
          </el-table-column>
          <el-table-column min-width="200">
            <template slot="header">
              <div class="table--header">
                <span>Orders today </span>
              </div>
            </template>
            <template v-slot="scope">
              <span
                >{{ scope.row.orders_today_completed }} /
                {{ scope.row.orders_today }}</span
              >
              <el-progress
                class="mt-10 ml-0"
                :percentage="
                  percent(
                    scope.row.orders_today_completed,
                    scope.row.orders_today,
                  )
                "
                :color="
                  setPercentColor(
                    scope.row.orders_today_completed,
                    scope.row.orders_today,
                  )
                "
                :stroke-width="5"
                :show-text="false"
              ></el-progress>
            </template>
          </el-table-column>
          <el-table-column width="160">
            <template slot="header">
              <div class="table--header">
                <span>Location Areas</span>
              </div>
            </template>
            <template v-slot="scope">
              <gardener-location-areas
                :location-areas="scope.row.location_areas"
              />
            </template>
          </el-table-column>
          <el-table-column width="64">
            <template v-slot="scope">
              <gardener-actions
                :gardener="scope.row"
                @action="updateGardener($event, scope.$index)"
              />
            </template>
          </el-table-column>
        </el-table>
        <eden-pagination
          v-if="showPagination"
          :from="from"
          :to="to"
          :total="total"
          :current-page.sync="page"
        />
      </template>
      <gardeners-export :show.sync="exporting" :data="pageData" />
    </div>
  </div>
</template>

<script>
import GardenerLocationAreas from "@/components/Gardeners/Gardener/Actions/GardenerLocationAreas";
import GardenerActions from "@/components/Gardeners/Gardener/Actions/GardenerActions";
import GardenersExport from "@/components/Gardeners/Export/GardenersExport";
import PaymentTrackerOverview from "@/components/Gardeners/PaymentTracker/PaymentTrackerOverview";

import { formatGardeners } from "@/components/Gardeners/Gardener/gardener.config";
import gardeners from "@/requests/gardeners";
import * as actions from "@/store/action-types";

export default {
  name: "Gardeners",
  components: {
    GardenerLocationAreas,
    GardenerActions,
    GardenersExport,
    PaymentTrackerOverview,
  },
  data() {
    return {
      period: "today",
      periods: {
        today: "Today",
        tomorrow: "Tomorrow",
        thisweek: "This week",
      },
      summary: {
        loading: false,
        data: {},
      },
      loading: false,
      page: 1,
      pageData: [],
      showPagination: true,
      exporting: false,
    };
  },
  computed: {
    stats() {
      const summary = this.summary.data;
      return [
        {
          label: "Total gardeners",
          figure: this.formatFigure(summary.total_gardeners),
        },
        {
          label: "Total orders today",
          figure: this.formatFigure(summary.total_orders_today),
        },
        {
          label: "Total orders completed",
          figure: this.formatFigure(summary.total_orders_completed_today),
        },
        {
          label: "Percentage completed",
          figure: this.formatPercentage(
            summary.percentage_of_orders_completed_today,
          ),
        },
      ];
    },
    gardeners() {
      return this.$store.getters.gardeners;
    },
    from() {
      return this.gardeners.pages[this.page].from;
    },
    to() {
      return this.gardeners.pages[this.page].to;
    },
    total() {
      return this.gardeners.total;
    },
    title() {
      const total = this.total;
      const result = this.pageData.length;
      return `${this.showPagination ? total || 0 : result} Gardeners`;
    },
  },
  watch: {
    page() {
      const pageFetched = !!this.gardeners.pages[this.page];
      if (pageFetched) {
        this.setPageData();
      } else {
        this.getGardeners();
      }
    },
  },
  created() {
    this.getGardenersSummary({ period: this.period });
    const pageFetched = !!this.gardeners.pages[this.page];
    if (pageFetched) {
      this.setPageData();
    } else {
      this.getGardeners();
    }
  },
  methods: {
    getGardenersSummary({ period, from, to }) {
      this.summary.loading = true;
      const { start_date, end_date } = this.formatStartAndEndDate({
        period,
        from,
        to,
      });

      gardeners
        .summary(start_date, end_date)
        .then((response) => {
          const { status, data } = response.data;
          if (status) {
            this.summary.data = data && data.summary;
            this.period = period;
          }
          this.summary.loading = false;
        })
        .catch((error) => {
          this.summary.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    getGardeners() {
      this.loading = true;
      this.$store
        .dispatch(actions.GET_GARDENERS, this.page)
        .then(() => {
          this.setPageData();
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    search(query) {
      if (query === "") {
        this.setPageData();
        return;
      }

      this.loading = true;
      gardeners
        .search(query)
        .then((response) => {
          this.pageData = formatGardeners(response.data.data.gardeners);
          this.showPagination = false;
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const errorMessage = error.response.data;
          if (errorMessage.errors) {
            const errorKeys = Object.keys(errorMessage.errors);
            this.$message.error(errorMessage.errors[errorKeys[0]].join(""));
          } else {
            this.$message.error(errorMessage.message);
          }
        });
    },
    setPageData() {
      this.pageData = this.parseData(this.gardeners.pages[this.page].data);
      this.pageData = formatGardeners(this.pageData);
      this.showPagination = !!this.pageData.length;
    },
    addGardener() {
      this.$router.push({ name: `gardeners.gardener-add` });
    },
    updateGardener(event, index) {
      switch (event.action) {
        case "assign-location-area":
          this.pageData[index].location_areas.push(...event.data);
          break;
        case "remove-location-area":
          this.pageData[index].location_areas = this.pageData[
            index
          ].location_areas.filter((area) => !event.data.includes(area.id));
          break;
        case "change-status":
          this.pageData[index].available = event.data;
          break;
        case "change-slack-id":
          this.pageData[index].slack_id = event.data;
          break;
        case "delete":
          this.pageData.splice(index, 1);
          break;
        default:
          break;
      }
    },
  },
};
</script>
