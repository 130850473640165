<template>
  <div>
    <el-dropdown @command="command">
      <span class="el-dropdown-link more">
        <i class="eden-icon-more"></i>
      </span>
      <el-dropdown-menu slot="dropdown">
        <template v-for="(action, index) in actions">
          <el-dropdown-item
            v-if="allowAccessFor(action.access)"
            :key="index"
            :icon="action.icon"
            :command="{
              action: action.name,
              component: action.component,
            }"
            >{{ action.label }}</el-dropdown-item
          >
        </template>
      </el-dropdown-menu>
    </el-dropdown>
    <component
      :is="action.component"
      :show.sync="action.visibility"
      :gardener="gardener"
      @success="emitUpdate"
    />
  </div>
</template>

<script>
export default {
  name: "GardenerActions",
  props: {
    gardener: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  components: {
    GardenerLocationAreaAdd: () => import("./GardenerLocationAreaAdd"),
    GardenerLocationAreaRemove: () => import("./GardenerLocationAreaRemove"),
    GardenerStatusChange: () => import("./GardenerStatusChange"),
    GardenerSlackIdChange: () => import("./GardenerSlackIdChange"),
    GardenerDelete: () => import("./GardenerDelete"),
  },
  data() {
    return {
      action: {
        command: "",
        component: "",
        visibility: false,
      },
      actions: [
        {
          access: ["superadmin", "admin", "operations"],
          icon: "eden-icon-add-circle",
          label: "Assign location area",
          name: "assign-location-area",
          component: "gardener-location-area-add",
        },
        {
          access: ["superadmin", "admin", "operations"],
          icon: "eden-icon-remove-circle",
          label: "Remove location area",
          name: "remove-location-area",
          component: "gardener-location-area-remove",
        },
        {
          access: "all",
          icon: "eden-icon-check-circle",
          label: "Change status",
          name: "change-status",
          component: "gardener-status-change",
        },
        {
          access: ["admin"],
          icon: "el-icon-link",
          label: "Change Slack Id",
          name: "change-slack-id",
          component: "gardener-slack-id-change",
        },
        {
          access: ["superadmin", "admin"],
          icon: "eden-icon-delete",
          label: "Delete gardener",
          name: "delete",
          component: "gardener-delete",
        },
      ],
    };
  },
  methods: {
    command(command) {
      this.action.command = command.action;
      this.action.component = command.component;
      setTimeout(() => {
        this.action.visibility = true;
      }, 100);
    },
    emitUpdate(data) {
      console.log(data);
      this.$emit("action", { action: this.action.command, data });
    },
  },
};
</script>

<style lang="scss"></style>
