<template>
  <div>
    <el-dialog
      title="Export"
      :visible.sync="shouldShow"
      @close="closeEvent"
      width="30%"
    >
      <eden-file-export
        :data="exportData"
        @exported="closeEvent"
        @cancelled="closeEvent"
      >
        <el-form label-position="top" :model="form">
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select the data you'd like to export">
                <el-checkbox-group v-model="form.fields">
                  <el-checkbox
                    v-for="(value, key, i) in fields"
                    :key="i"
                    :disabled="key === 'name'"
                    :label="key"
                  >
                    {{ value }}
                  </el-checkbox>
                </el-checkbox-group>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex">
            <el-col :span="24">
              <el-form-item label="Select data format">
                <el-radio-group v-model="form.format">
                  <el-radio
                    v-for="(key, value, i) in formats"
                    :key="i"
                    :label="value"
                  >
                    {{ key }}
                  </el-radio>
                </el-radio-group>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="is-flex justify-end">
          <el-button
            type="primary"
            :loading="exporting"
            @click="getExportData"
            :disabled="!form.format || !form.fields.length"
            >Export</el-button
          >
        </div>
      </eden-file-export>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "GardenersExport",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },

  data() {
    return {
      fields: {
        name: "Name",
        status: "Status",
        customers: "Customers",
        orders_today: "Orders",
        location_areas: "Location Area",
      },
      formats: {
        pdf: "PDF",
        csv: "CSV",
        xls: "Excel",
      },
      form: {
        fields: ["name"],
        format: "",
      },
      exportData: {},
      exporting: false,
    };
  },
  computed: {
    shouldShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit("update:show", value);
      },
    },
  },
  watch: {
    exporting() {
      if (!this.exporting) {
        this.closeEvent();
      }
    },
  },
  methods: {
    closeEvent() {
      this.form.fields = ["name"];
      this.form.format = "";
      this.exporting = false;
      this.shouldShow = false;
    },

    getExportData() {
      this.loading = true;
      this.formatExportData(this.data);
      this.mixpanelTrack({
        event: "gardeners_export",
      });
    },

    formatExportData(data) {
      let title;
      let date = this.formatDateAndTime(new Date(), "do m, y");
      title = `Gardeners List: Exported at - ${date}`;

      let headers = {
        sn: "S/N",
      };
      let extra_headers = {};
      let gardenersData = [];
      this.form.fields.forEach(
        (key) => (extra_headers[key] = this.fields[key])
      );
      headers = { ...headers, ...extra_headers };
      gardenersData = data.map((item, index) => {
        let export_object = {};
        Object.keys(headers).forEach((key) => {
          switch (key) {
            case "sn":
              export_object[key] = index + 1;
              break;
            case "name":
              export_object[key] = this.formatName(item.name);
              break;
            case "status":
              export_object[key] = item.available
                ? "Available"
                : "Not Available";
              break;
            case "customers":
              export_object[key] =
                item.customers + " / " + item.customers_threshold;
              break;
            case "orders_today":
              export_object[key] =
                item.orders_today + " / " + item.orders_today_completed;
              break;
            case "location_areas":
              export_object[key] = item.location_areas.map((item_location) => {
                return `${item_location.name}\n`;
              });
              break;
            default:
              break;
          }
        });
        return export_object;
      });
      this.exportData = {
        title,
        headers,
        data: gardenersData,
        fileName: "Gardeners List",
        titleMargin: 44,
        format: this.form.format,
      };
      this.exporting = false;
    },
  },
};
</script>
<style lang="scss" scoped></style>
