var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home"},[_c('eden-page-header',{attrs:{"title":'Gardeners'}},[_c('template',{slot:"actions"},[_c('div',{staticClass:"page--head-actions is-flex is-flex-wrap align-center"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.addGardener}},[_vm._v(" Add Gardener ")])],1)])],2),_c('eden-page-stats',{attrs:{"title":'Overview',"loading":_vm.summary.loading,"stats":_vm.stats,"show-periods":true,"periods":_vm.periods},on:{"set-period":_vm.getGardenersSummary}}),(_vm.allowAccessFor(['admin', 'gardener_pro']))?_c('payment-tracker-overview',{attrs:{"view":'admin'}}):_vm._e(),_c('div',[_c('eden-table-actions',{attrs:{"title":_vm.title},on:{"search":_vm.search}},[_c('template',{slot:"actions"},[_c('el-button',{staticClass:"ml-10",attrs:{"type":"plain","icon":"eden-icon-upload","disabled":_vm.loading || !_vm.pageData.length},on:{"click":function($event){_vm.exporting = true}}},[_vm._v(" Export ")])],1)],2),(_vm.loading)?[_vm._v("Loading...")]:[_c('el-table',{attrs:{"data":_vm.pageData}},[_c('el-table-column',{attrs:{"min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                name: 'gardeners.gardener',
                params: { id: scope.row.id },
              }}},[_c('span',{staticClass:"font-sm text-primary"},[_vm._v(" "+_vm._s(_vm.formatName(scope.row.name)))])]),_c('span',{staticClass:"text-grey-tertiary"},[_vm._v(_vm._s(scope.row.email))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Name")])])])],2),_c('el-table-column',{attrs:{"width":"120"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"text-grey-tertiary"},[_vm._v(_vm._s(scope.row.slack_id || "-"))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Slack Id")])])])],2),_c('el-table-column',{attrs:{"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',{staticClass:"font-sm table--status"},[_c('el-tag',{attrs:{"type":scope.row.available ? 'success' : 'warning'}},[_vm._v(_vm._s(scope.row.available ? "Available" : "Not Available"))])],1)]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Status")])])])],2),_c('el-table-column',{attrs:{"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.customers)+" / "+_vm._s(scope.row.customers_threshold))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Customers ")])])])],2),_c('el-table-column',{attrs:{"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('span',[_vm._v(_vm._s(scope.row.orders_today_completed)+" / "+_vm._s(scope.row.orders_today))]),_c('el-progress',{staticClass:"mt-10 ml-0",attrs:{"percentage":_vm.percent(
                  scope.row.orders_today_completed,
                  scope.row.orders_today,
                ),"color":_vm.setPercentColor(
                  scope.row.orders_today_completed,
                  scope.row.orders_today,
                ),"stroke-width":5,"show-text":false}})]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Orders today ")])])])],2),_c('el-table-column',{attrs:{"width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('gardener-location-areas',{attrs:{"location-areas":scope.row.location_areas}})]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"table--header"},[_c('span',[_vm._v("Location Areas")])])])],2),_c('el-table-column',{attrs:{"width":"64"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('gardener-actions',{attrs:{"gardener":scope.row},on:{"action":function($event){return _vm.updateGardener($event, scope.$index)}}})]}}])})],1),(_vm.showPagination)?_c('eden-pagination',{attrs:{"from":_vm.from,"to":_vm.to,"total":_vm.total,"current-page":_vm.page},on:{"update:currentPage":function($event){_vm.page=$event},"update:current-page":function($event){_vm.page=$event}}}):_vm._e()],_c('gardeners-export',{attrs:{"show":_vm.exporting,"data":_vm.pageData},on:{"update:show":function($event){_vm.exporting=$event}}})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }